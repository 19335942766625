<template>
  <div class="page">
    <div class="items">
      <p class="titleBlock">Кейсы</p>
      <div class="item" v-for="(item,ind) in itemsCase" v-if="activeCase > ind">
        <div class="title"><p><span>Клиент:</span> {{item.client}}</p></div>
        <div class="progress">
          <p>Что было сделано:</p>
          <p class="text">{{item.text}}</p>
        </div>
        <div class="result">
          <p>Результат:</p>
          <p class="text">{{item.result}}</p>
        </div>
      </div>
      <p class="more" @click="moreItems" v-text="activeCase === 3?'Показать все':'Скрыть'"></p>
    </div>
    <div class="itemsImage">
      <p class="titleBlock">Клиенты</p>
      <div class="itemCont">
        <img src="../../public/imageLogo/8.png" alt="">
      </div>
      <div class="itemCont">
        <img src="../../public/imageLogo/7.png" alt="">
      </div>
      <div class="itemCont">
        <img src="../../public/imageLogo/6.png" alt="">
      </div>
      <div class="itemCont">
        <img src="../../public/imageLogo/5.png" alt="">
      </div>
      <div class="itemCont">
        <img src="../../public/imageLogo/4.png" alt="">
      </div>
      <div class="itemCont">
        <img src="../../public/imageLogo/3.png" alt="">
      </div>
      <div class="itemCont">
        <img src="../../public/imageLogo/2.png" alt="">
      </div>

      <div class="itemCont">
        <img src="../../public/imageLogo/1.png" alt="">
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "case",
  data() {
    return {
      activeCase: 3,
      itemsCase: [
        {
          client: 'ООО "Фишт"',
          text: 'Защита интересов компании в ФАС по вопросу включения в реестр недобросовестных поставщиков в результате злоупотребления заказчика правами.',
          result: 'В результате выстроенной стратегии защиты, ФАС приняла решение об отсутствии в действиях клиента признаков недобросовестности, компания, сохранила право на участие в тендерных процедурах. '
        },
        {
          client: 'Компания клиента',
          text: 'Подготовка положения по обработке персональных данных в соответствии с General Data Protection Regulation.',
          result: 'Провели комплексный и глубокий анализ деятельности компании клиента и подготовили Политику конфиденциальности, Уведомление о конфиденциальности, Политику использования файлов cookie и Уведомление о файлах cookie в соответствии с требованиями Общего регламента защиты персональных данный Европейского Союза '
        },
        {
          client: 'Ленинградская Федерация Профсоюзов',
          text: 'Представление интересов Общественной организации Межрегиональное Санкт-Петербурга и Ленинградской области объединение организаций профсоюзов «Ленинградская Федерация Профсоюзов» в Арбитражном суде.',
          result: 'По результатам рассмотрения спора в суде, в пользу клиента взыскано более 21 000 000 рублей.'
        },
        {
          client: 'Строительная компания',
          text: 'Защита интересов строительной компании в Государственной инспекции труда по Санкт-Петербургу.',
          result: 'Был проведен аудит компании в области охраны труда, оценки профессиональных рисков, документация была приведена в соответствие с действующим законодательством. \n' +
              'После рассмотрения запрашиваемых документов ГИТ было выдано предписание на устранение незначительных недостатков без взыскания штрафа.'
        },
        {
          client: 'ООО «СТС-Ладога»',
          text: 'Представление интересов общества в Арбитражном суде при рассмотрении спора с Фондом капитального строительства.',
          result: 'В результате выстроенной стратегии, с Фонда капительного  строительства, в полном объеме были взысканы денежные средства.'
        },
        {
          client: 'ООО «Искандер-рус»',
          text: 'Представление интересов общества в Арбитражных судах трех инстанций, при рассмотрении спора по договору строительного подряда.',
          result: 'По результатам рассмотрения спора в суде, в пользу клиента взыскано более 5 500 000 рублей. '
        },
        {
          client: 'Компания клиента',
          text: 'Успешно провели переговоры с компанией Восток-Запад - крупнейшим поставщиком продуктов в рестораны, кафе, гостиницы ',
          result: 'По результатам длительных переговоров удалось существенно снизить предъявленную к возмещению сумму и добиться рассрочки исполнения обязательств.'
        },
        {
          client: 'ФГБУ «Центральный музей железнодорожного транспорта» ',
          text: 'Защита интересов клиента в арбитражных судах трех инстанций по спору с ПГУПС Императора Александра I.',
          result: 'В результате выстроенной стратегии защиты, исковые требования университета, оставлены без удовлетворения, решение суда первой инстанции оставлено без изменения, судами апелляционной и кассационной инстанции.'
        },
        {
          client: 'УСМР-73',
          text: 'Представление интересов общества в Арбитражном суде по спору с жилищным кооперативом .',
          result: 'По результатам рассмотрения спора в суде, исковые требования  клиента удовлетворены в полном объеме, с недобросовестного клиента взыскано более 3 300 000 руб.'
        },
      ],
    }
  },
  methods: {
    moreItems(){
      this.activeCase = this.activeCase === 3 ? 20 : 3
    }
  },
  created() {
    document.title = 'IzvarinLaw - Кейсы';
  }
}
</script>

<style scoped lang="sass">
  =r($width)
    @media only screen and (max-width: $width+ "px")
      @content
  .page
    width: 100%
    padding-left: 100px
    display: flex
    flex-wrap: wrap
    +r(1200)
      padding-left: 30px
    +r(750)
      padding-top: 50px
  .items
    display: flex
    padding-top: 20px
    flex-direction: column
    width: 60%
    +r(1500)
      width: 80%
    +r(998)
      padding-top: 0
    +r(750)
      width: 100%
      padding-right: 30px
  .item
    display: flex
    position: relative
    flex-direction: column
    &:before
      position: absolute
      width: 50%
      background-color: #006D5A
      content: ''
      height: 3px
      bottom: 40px
      left: 0
    p
      margin: 0
      padding: 0
    .title
      font-size: 20px
      margin-bottom: 10px
      +r(750)
        font-size: 16px
      p
        font-weight: 400
      span
        color: #006D5A
        font-weight: 500
        margin-right: 5px
    p
      font-size: 18px
      font-weight: 600
      margin-bottom: 5px
      +r(750)
        font-size: 16px
    .text
      font-size: 16px
      font-weight: 500
      line-height: 1.7
      opacity: 0.4
      color: #DADADA
    .progress
      margin-bottom: 15px
    .result
      margin-bottom: 80px
  .titleBlock
    width: 100%
    margin-bottom: 30px
    font-size: 24px
    font-weight: 500
    +r(750)
      font-size: 18px
      margin-bottom: 30px
  .itemsImage
    display: flex
    flex-wrap: wrap
    width: 80%
    align-items: flex-start
    height: max-content
    +r(998)
      width: calc( 100% - 60px )
    .itemCont
      width: 50%
      margin-bottom: 50px
      +r(1700)
        img
          max-width: 80%
      +r(998)
        width: 100%
        img
          max-width: 100%
  .more
    font-size: 20px
    color: #006D5A
    text-decoration: underline
    margin-bottom: 50px
    cursor: pointer
    &:hover
      color: #DB6500
</style>